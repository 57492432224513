
  import { defineComponent, onMounted, computed, ref } from 'vue';
  import { getIllustrationsPath } from '@/core/helpers/assets';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useRouter } from 'vue-router';

  export default defineComponent({
    name: 'notifications-menu',
    components: {},

    setup() {
      const store = useStore();
      const router = useRouter();
      const isLoading = ref(false);
      const pageChanged = async () => {
        isLoading.value = true;
        await store.dispatch(Actions.UPDATE_NOTIFICATONS_CURRENT_PAGE, 1);
        await store.dispatch(Actions.GET_NOTIFICATIONS);
        isLoading.value = false;
      };

      const page = computed(() => store.getters.getPage);
      const pageCount = computed(() => store.getters.getPagesCount);
      const showMore = () => {
        return page.value < pageCount.value;
      };
      const markAsRead = async (notificationItem) => {
        await store.dispatch(Actions.GET_NOTIFICATION, {
          data: '',
          id: notificationItem.id,
        });

        if (notificationItem.type === 'hubs-shipments') {
          router
            .push({
              name: 'hub-shipment-editing',
              params: { id: notificationItem.entityId },
            })
            .then(() => {
              location.reload();
            });
        }
        if (
          notificationItem.type === 'merchent-shipments' ||
          notificationItem.type === 'supplier-shipments' ||
          notificationItem.type === 'new-supplier-shipment' ||
          notificationItem.type === 'new-merchant-shipment' ||
          notificationItem.type === 'approved-supplier-shipment' ||
          notificationItem.type === 'approved-merchant-shipment'
        ) {
          router
            .push({
              name: 'shipment-editing',
              params: { id: notificationItem.entityId },
            })
            .then(() => {
              location.reload();
            });
        }
        await store.dispatch(Actions.GET_NOTIFICATIONS);
      };

      const notifications = computed(() => store.getters.notificationsList);

      const fetchNotifications = async () => {
        try {
          await store.dispatch(Actions.GET_NOTIFICATIONS);
        } catch (error) {
          console.error('Error fetching notifications:', error);
        }
      };

      onMounted(async () => {
        fetchNotifications();
        setInterval(fetchNotifications, 200000);
      });

      return {
        notifications,
        showMore,
        pageCount,
        page,
        pageChanged,
        isLoading,
        markAsRead,
        getIllustrationsPath,
      };
    },
  });
