
  import { defineComponent, computed } from 'vue';
  import KTUserMenu from '@/layout/header/partials/UserMenu.vue';
  import KTNotificationsMenu from '@/layout/header/partials/NotificationsMenu.vue';
  import { useStore } from 'vuex';

  export default defineComponent({
    name: 'topbar',
    components: {
      KTUserMenu,
      KTNotificationsMenu,
    },
    setup() {
      const store = useStore();

      // ... other setup code ...

      const allNotificationSeen = computed(
        () => store.getters.notificationSeen
      );

      return {
        allNotificationSeen,
      };
    },
  });
