const DocMenuConfig = [
  {
    heading: 'Manage',
    route: '/manage',
    pages: [
      {
        sectionTitle: 'member',
        route: '/members',
        svgIcon: '/media/icons/duotune/technology/teh004.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'read',
          resource: [
            'users',
            'roles-permissions',
            'admins',
            'merchant-account',
          ],
        },
        sub: [
          {
            heading: 'Roles & Permission',
            route: '/manage/members/groups-listing',
            ability: {
              method: 'read',
              resource: 'roles-permissions',
            },
          },
          {
            sectionTitle: 'Users',
            route: '/manage/members/users',
            ability: {
              method: 'read',
              resource: 'users',
            },
            sub: [
              {
                heading: 'Users',
                route: '/manage/members/users-listing',
                ability: {
                  method: 'read',
                  resource: 'users',
                },
              },
              {
                heading: 'Merchant Accounts',
                route: '/manage/members/users/merchant-listing',
                ability: {
                  method: 'read',
                  resource: 'merchant-account',
                },
              },
            ],
          },

          {
            heading: 'Admins',
            route: '/manage/members/admins-listing',
            ability: {
              method: 'read',
              resource: 'admins',
            },
          },
        ],
      },
      {
        sectionTitle: 'Shipments',
        route: '/manage-shipments',
        svgIcon: '/media/icons/duotune/ecommerce/ecm006.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'read',
          resource: [
            'hubs-shipments',
            'merchent-shipments',
            'supplier-shipments',
          ],
        },
        sub: [
          {
            heading: 'Supplier Shipments',
            route: '/manage/shipments/supplier-shipments-listing',
            ability: {
              method: 'read',
              resource: 'supplier-shipments',
            },
          },
          {
            heading: 'Merchant Shipments',
            route: '/manage/shipments/merchant-shipments-listing',
            ability: {
              method: 'read',
              resource: 'merchent-shipments',
            },
          },
          {
            heading: 'Hub Shipment',
            route: '/manage/hub-shipments/hub-shipment-listing',
            ability: {
              method: 'read',
              resource: 'hubs-shipments',
            },
          },
        ],
      },
      {
        sectionTitle: 'Packages',
        route: '/Packages',
        svgIcon: '/media/icons/duotune/ecommerce/ecm006.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'read',
          resource: [
            'supplier-packages',
            'hub-packages',
            'merchant-packages',
            'unsigned-packages',
          ],
        },
        sub: [
          {
            heading: 'Supplier Packages',
            route: '/manage/packages/supplier-packages-listing',
            ability: {
              method: 'read',
              resource: 'supplier-packages',
            },
          },
          {
            heading: 'Merchant Packages',
            route: '/manage/packages/merchant-packages-listing',
            ability: {
              method: 'read',
              resource: 'merchant-packages',
            },
          },
          {
            heading: 'Hub Packages',
            route: '/manage/packages/hub-packages-listing',
            ability: {
              method: 'read',
              resource: 'hub-packages',
            },
          },
          {
            heading: 'Unassigned Packages',
            route: '/manage/packages/unassigned-listing',
            ability: {
              method: 'read',
              resource: 'unsigned-packages',
            },
          },
        ],
      },
      {
        sectionTitle: 'Assets',
        route: '/assets',
        svgIcon: '/media/icons/duotune/technology/teh004.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'read',
          resource: [
            'shipment-size',
            'governorates',
            'vehicle-types',
            'driver',
            'areas',
            'hubs',
            'taxi-meter',
            'currency',
          ],
        },
        sub: [
          {
            heading: 'Governorates',
            route: '/manage/assets/governorates-listing',
            ability: {
              method: 'read',
              resource: 'governorates',
            },
          },
          {
            heading: 'Shipment Size',
            route: '/manage/assets/shipment-size-listing',
            ability: {
              method: 'read',
              resource: 'shipment-size',
            },
          },
          {
            heading: 'Vehicles',
            route: '/manage/assets/vehicles-listing',
            ability: {
              method: 'read',
              resource: 'vehicle-types',
            },
          },
          {
            heading: 'Areas',
            route: '/manage/assets/areas-listing',
            ability: {
              method: 'read',
              resource: 'areas',
            },
          },
          {
            heading: 'Drivers',
            route: '/manage/assets/drivers-listing',
            ability: {
              method: 'read',
              resource: 'driver',
            },
          },
          {
            heading: 'Hubs',
            route: '/manage/assets/hubs-listing',
            ability: {
              method: 'read',
              resource: 'hubs',
            },
          },
          {
            heading: 'Taxi Meter',
            route: '/manage/assets/taxi-meter-listing',
            ability: {
              method: 'read',
              resource: 'taxi-meter',
            },
          },
          {
            heading: 'Exchange Rate',
            route: '/manage/assets/exchange-rate-listing',
            ability: {
              method: 'read',
              resource: 'currency',
            },
          },
        ],
      },
      {
        sectionTitle: 'Clients',
        route: '/clients',
        svgIcon: '/media/icons/duotune/technology/teh004.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'read',
          resource: ['clients', 'offices', 'category'],
        },
        sub: [
          {
            heading: 'Clients',
            route: '/manage/clients/clients-listing',
            ability: {
              method: 'read',
              resource: 'clients',
            },
          },
          {
            heading: 'Offices',
            route: '/manage/clients/offices-listing',
            ability: {
              method: 'read',
              resource: 'offices',
            },
          },
          {
            heading: 'Categories',
            route: '/manage/clients/categories-listing',
            ability: {
              method: 'read',
              resource: 'category',
            },
          },
        ],
      },
    ],
  },
  {
    heading: 'Settings',
    route: '/settings',
    ability: {
      method: 'read',
      resource: ['about-us', 'contact-us', 'terms-conditions'],
    },
    pages: [
      {
        heading: 'About Us',
        route: '/manage/settings/about-us-listing',
        ability: {
          method: 'read',
          resource: 'about-us',
        },
      },
      {
        heading: 'Contact Us',
        route: '/manage/settings/contact-us-listing',
        ability: {
          method: 'read',
          resource: 'contact-us',
        },
      },
      {
        heading: 'Terms & Conditions',
        route: '/manage/settings/terms-conditions',
        ability: {
          method: 'read',
          resource: 'terms-conditions',
        },
      },
    ],
  },
];

export default DocMenuConfig;
